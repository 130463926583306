.wheel-container {
    position: fixed;
    top: 0;
    z-index: 1000000;
    width: 100%;
    left: 0;
    height: 100%;
    background-color: white;
    opacity: 0.5;
}
.wheel-container div:first-child {
    top: 50%;
    position: absolute;
    left: 48%;
    opacity: 1;
}