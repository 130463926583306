.form-container {
	background-color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 700px) {
    .form-container {
        flex-direction: column;
    }
    .actions {
        position: fixed;
        bottom: 0;
        padding: 13px 8% 3% 14%;
        border: 1px solid;
        left: 56px;
        width: 85%;
        background: #303030;
    }
}

.form-container .MuiFormLabel-root.Mui-focused {
	color: #00b359;
}
.form-container .MuiInput-underline:after {
	border-bottom: 2px solid #00b359;
}

select {
    width: 170px;
    background: white;
    border: 1px solid gray;
    margin-bottom: 10px;
    height: 35px;
    font-size: 16px;
}

.form-group span {
    font-size: 12px;
    color: gray;
    display: block;
}

select:focus {	
    outline: 1px solid #00b359;
}

.logo-display{
    margin-top: 40px;
}

.delete-file {
    color: red;
    cursor: pointer;
}
.form-group .open-salarios {
    color: #00b359;
    margin-bottom: 10px;
    cursor: pointer;
}
.open-salarios:hover {
    text-decoration: underline;
}