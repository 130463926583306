.table-container {
	background-color: white;
	opacity: 1.0;
}

.table-container .MuiSvgIcon-root, .add-role h3 {
  	color: #00b359;
}

.table-container .MuiInput-underline:after {
	border-bottom: #00b359;
}

.add-role {
	padding: 10px;
	cursor: pointer;
}
.add-role h3 {
	display: inline-block;
	vertical-align: super;
}
.add-role:hover h3 {
	text-decoration: underline;
}