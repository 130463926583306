body {
  margin: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  /* opacity: 0.1; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiDrawer-paperAnchorLeft.makeStyles-drawerPaperClose-14 .logo-img, .MuiDrawer-paperAnchorDockedLeft.jss14 .logo-img{
  width: 50px;
  left: 5px;
}
.MuiDrawer-paperAnchorDockedLeft .logo-img {
    width: 150px;
    position: absolute;
    bottom: 50px;
    left: 40px;
}
.navigation a{
  color: white;
}
.active .MuiSvgIcon-root {
  color: red;
}

.navigation a.active{
  color: red;
}

.MuiPaper-root.MuiDrawer-paper.makeStyles-drawerPaper-71.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0, .MuiPaper-root.MuiDrawer-paper.jss13.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0, .MuiDrawer-root.MuiDrawer-docked, .MuiPaper-root {
    background-color: #303030 !important;
    color: white !important;
}

.MuiSvgIcon-root {
  color: white;
}

header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionAbsolute.MuiAppBar-colorPrimary {
    background-color: #cc0000 !important;
}
.MuiPaper-rounded {
    background-color: white !important;
    color: black !important;
}
.form-group.buttons {
  margin-top: 20px;
}
.fade-bg {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('./assets/img/bg-blueprint2.jpg') center center;
    background-size: 100% 100%;
    opacity: .5;   
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
    background-position:center;
}
.btn.btn-save {
    background-color: #00b359;
    display: inline-block;
    color: white;
}
.btn.btn-cancel {
    background-color: white;
    border: 2px solid red;
    display: inline-block;
    color: red;
}

.btn {
    font-weight: bold;
    font-size: 16px;
    min-width: 150px;
    height: 50px;
    cursor: pointer;
    margin-right: 10px;
}

.btn:hover {
  text-decoration: underline;
}

.input-form {
  margin-bottom: 10px !important;
}

.table-container .MuiSvgIcon-root.red-icon {
  color: #e62e00;
}
.error-input .MuiInput-underline:before {
  border-bottom: 1px solid red;
}
.error-input .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid red;
}
.invalid-feedback {
  text-align: center;
}
form {
  min-width: 350px;
}
form .MuiFormControl-root {
  width: 100%;
}
form select {
  width: 100%;
}
.invalid-feedback.add-form {
    color: red;
    font-size: 10px;
    margin: -10px 0 10px;
    text-align: initial;
}
.user-section .MuiListItemIcon-root {
    min-width: 30px;
}
.user-section span {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}
.user-section img {
    border-radius: 20px;
    margin-right: 5px;
    border: 1px solid white;   
    height: 40px;
}
.open-link {
    color: #00b359;
    margin-bottom: 10px;
    cursor: pointer;
}
.open-link:hover {
    text-decoration: underline;
}
a span.MuiButton-label {
  color: #00b359;
}
@media (max-width: 900px) {
    button.btn {
      font-size: 14px;
      width: 100px;
      height: 30px;
    }
    form {
      min-width: fit-content;
    }
}