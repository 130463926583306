
.login-page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('./../assets/img/background-blueprint.jpg') center center;
  background-size: 100% 100%;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-size:cover;
  background-position:center;  
  overflow-y: scroll;
}

.logo-img {
  width: 200px;
  margin: auto;
  display: block;
}

.login-page form {
  background-color: white;
  width: 300px;
  margin: 50px auto;
  padding: 10px;
  text-align: center;
  -webkit-box-shadow: 10px 10px 37px 19px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 37px 19px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 37px 19px rgba(0,0,0,0.75);
}

.login-page form .input-login {
  margin-top: 8px;
  color: black;
}
.login-page .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #00b359;
}
.login-page .MuiFormLabel-root.Mui-focused {
  color: #00b359;
}
.login-page form button, .login-page form .input-login {
  width: 200px;
}

.login-page form button {
  margin: 10px;
  background-color: #cc0000;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  height: 40px;
}

.has-error {
  border-color: red;
}

.invalid-feedback {
  color: red;
}