.form-container {
	background-color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}
.form-container .MuiFormLabel-root.Mui-focused {
	color: #00b359;
}
.form-container .MuiInput-underline:after {
	border-bottom: 2px solid #00b359;
}

.form-group span {
    font-size: 12px;
    color: gray;
    display: block;
}
/* Customize the label (the container) */
.check-box {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 20px;
}

/* Hide the browser's default checkbox */
.check-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.check-box:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check-box input:checked ~ .checkmark {
  background-color: #00b359;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-box input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-box .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}