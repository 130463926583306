.distribuciones-row {
    min-width: 600px;
}
.distribuciones-row p, .distribuciones-row input, .distribuciones-row .MuiFormGroup-root, .distribuciones-row .form-group, .distribuciones-row span {
    display: inline-block;
    margin-right: 10px;
}
.distribuciones-row p {
    width: 150px;
    padding-left: 10px;
}
.distribuciones-row .MuiFormGroup-root input {
    width: 100px;
}
.distribuciones-row .form-group input {
    width: 80px;
    margin-right: 10px;
}
.distribuciones-row .form-group .input-form {
    margin-right: 10px;
}
.distribuciones-header span {
    display: inline-block;
}
.distribuciones-header {
    font-weight: bold;
}
.distribuciones-header .nombre {
    width: 150px;
}
.distribuciones-header .nivel {
    width: 85px;
}
.distribuciones-header .area {
    width: 110px;
}
.distribuciones-header, .distribuciones-row {
    min-width: 700px;
}
.distribuciones-table {
    overflow-y: hidden;
}
.big-container {
    width: 100%;
}
.data-column {
    width: 40%;
    display: inline-block;
    margin: 0 20px;
    vertical-align: bottom;
}
@media (max-width: 700px) {
    .form-container {
        flex-direction: column;
    }
    .data-column {
        width: 100%;
        margin: 0 0;
    }
    .actions {
        position: fixed;
        bottom: 0;
        padding: 13px 8% 3% 14%;
        border: 1px solid;
        left: 56px;
        width: 85%;
        background: #303030;
    }
}