.oferta-cont{
    width: 100%;
}

.cont{
    width: 40%;
}

.ofertas li {
    cursor: pointer;
}

.ofertas li.selected {
    text-decoration: underline;
}

.ofertas li .action{
    color: #00b359;
}

.ofertas li .action:hover{
    text-decoration: underline;
}

.cambios {
    margin-top: 50px;
}

.oferta-vieja {
    color: red;
}

.nueva-oferta {
    text-decoration: underline;
    color: #00b359;
    cursor: pointer;
}

.oferta-vieja-text {
    text-decoration: line-through;
}