.table-container {
	background-color: white;
	opacity: 1.0;
}

.multi-step {
    display: flex;
    justify-content: center;
}

.multi-step .step-selector {
    width: 100px;
    height: 100px;
    margin-right: 0;
    border: 1px solid gray;
    border-radius: 50%;
    background-color: white;
    color: gray;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    z-index: 1;
}
.multi-step .step-selector.active, .multi-step .step-selector.passed {
    cursor: pointer;
}

.multi-step .join {
    border-bottom: 20px solid gray;
    width: 7%;
    height: 0;
    padding-top: 42px;
    margin-right: -1%;
    margin-left: -1%;
}

.inner-circle {
    border: 3px solid gray;
    border-radius: 50%;
    height: 85px;
    width: 85px;
    margin: 0 auto;
}
.inner-circle span {
    display: inline-block;
    margin-top: 30px;
    font-weight: bold;
}

/* Step colors */

/* red */
.multi-step .join.purple.active, .step-selector.purple.active .inner-circle, .multi-step .step-selector.purple.active {
    border-color: #c00;
    color: black;
}
/* lig passed */
.multi-step .join.purple.passed, .step-selector.purple.passed .inner-circle, .multi-step .step-selector.purple.passed {
    background-color: #c00;
    border-color: white;
    color: white;
}
.join.purple.passed, .join.purple.active {
    background-color: unset !important;
    border-color: #c00 !important;
}

@media (max-width: 900px) {
    .multi-step .step-selector {
        width: 45px;
        height: 40px;
    }
    .inner-circle {
        width: 30px;
        height: 30px;
    }
    .inner-circle span {
        display: none;
    }
    .multi-step .join {
        border-bottom: 10px solid gray;
        padding-top: 16px;
    }
}