.rank-hldr {
    position: relative;
}

.rank{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0px 1px 5px grey;
    margin-right: 4px;
    cursor: pointer;
}

.rank1{
    background-color: white;
}

.rank2{
    background-color: #00b359;
}

.rank3{
    background-color: yellow;
}

.rank4{
    background-color: orange;
}

.rank5{
    background-color: #e62e00;
}

.rank-options {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: white;
    display: flex;
    flex-direction: row;
}