.acabado img {
    width: 250px;
    height: 200px;
}
.acabado {
    width: 252px;
    height: 226px;
    display: inline-block;
    border: 1px solid gray;
    margin: 10px;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
}
.acabado:hover {
    border: 3px solid #00b359;
}
.acabado.selected {
    border: 3px solid red;
}
.acabado:hover span{
    color: #00b359;
    font-weight: bold;
}

@media (max-width: 900px) {
    .acabado, .acabado img {
        width: 225px;
    }
}
@media (max-width: 700px) {
    .form-container {
        flex-direction: column;
    }
    .actions {
        position: fixed;
        bottom: 0;
        padding: 13px 8% 3% 14%;
        border: 1px solid;
        left: 56px;
        width: 85%;
        background: #303030;
    }
}