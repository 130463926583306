.table-container {
	background-color: white;
	opacity: 1.0;
}

.table-container .MuiSvgIcon-root, .add-user h3 {
  	color: #00b359;
}

.table-container .MuiInput-underline:after {
	border-bottom: #00b359;
}

.add-user {
	padding: 10px;
	cursor: pointer;
}
.add-user h3 {
	display: inline-block;
	vertical-align: super;
}
.add-user:hover h3 {
	text-decoration: underline;
}