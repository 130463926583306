select.input-form {
    width: auto;
}
.distribuciones-row {
    min-width: 600px;
}
.distribuciones-row p, .distribuciones-row input, .distribuciones-row .MuiFormGroup-root, .distribuciones-row .form-group, .distribuciones-row span {
    display: inline-block;
    margin-right: 10px;
}
.distribuciones-row p {
    width: 180px;
    padding-left: 10px;
}
.distribuciones-row .MuiFormGroup-root input {
    width: 100px;
}
.distribuciones-row .form-group input {
    width: 80px;
    margin-right: 10px;
}
.distribuciones-row .form-group .input-form {
    margin-right: 10px;
}
.distribuciones-header span {
    display: inline-block;
}
.distribuciones-header {
    font-weight: bold;
}
.distribuciones-row .MuiFormControl-root {
    width: auto;
    margin-top: 5px !important;
}
svg.MuiSvgIcon-root.delete-icon {
    color: red;
    vertical-align: sub;
    cursor: pointer;
}
.distribuciones-header .nombre {
    width: 200px;
}
.distribuciones-header .nivel {
    width: 85px;
}
.distribuciones-header .area {
    width: 110px;
}
.distribuciones-header, .distribuciones-row {
    min-width: 780px;
}
.save-right {
    float: right;
    font-size: 50px !important;
    cursor: pointer;
}
.distribuciones-table {
    overflow-y: hidden;
}
.drop-dist svg.MuiSvgIcon-root {
    font-size: 40px;
    vertical-align: middle;
    cursor: pointer;
}
@media (max-width: 700px) {
    .form-container {
        flex-direction: column;
    }
    .actions {
        position: fixed;
        bottom: 0;
        padding: 13px 8% 3% 14%;
        border: 1px solid;
        left: 56px;
        width: 85%;
        background: #303030;
    }
}